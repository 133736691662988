<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="page-header">
      <h1>{{ $t("VISIBILITE-METIER") }}</h1>
    </div>

    <div class="content" v-if="!getLoadingprestation">
      <div class="content-header">
        <searchBar
          :placeholder="$t('CATEGORY_SEARCH')"
          :searchFunc="allcategorieMetier"
          @returnValue="updateSearch($event)"
        ></searchBar>
      </div>
      <div class="filtre">
        <p class="table-count">
          {{ getTotalRowcategorieMetier }} {{ $t("CATMETIER") }}
        </p>
      </div>
      <div class="table-rapport-style w-100">
        <table>
          <thead style="border-radius: 5px 0px 0px 0px">
            <tr>
              <th style="border-radius: 0px 5px 0px 0px">
                {{ $t("WORDING") }}
              </th>
              <th>
                {{ $t("ACTIVER") }}
              </th>
            </tr>
          </thead>
          <tbody v-for="(data, i) in computedAllMetier" :key="i">
            <tr role="row" class="ligneNormale">
              <td aria-colindex="1" role="cell">
                {{ data.lib }}
              </td>
              <td aria-colindex="2" role="cell">
                <b-form-checkbox
                  v-model="data.active"
                  name="check-button"
                  switch
                  @change="active(data)"
                >
                </b-form-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <b-pagination
        v-model="page"
        :total-rows="getTotalRowcategorieMetier"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
    </div>
    <div class="text-center center-spin" v-else>
      <b-spinner
        variant="primary"
        label="Spinning"
        class="big-spin"
      ></b-spinner>
    </div>
    <CommentMarche></CommentMarche>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CommentMarche from "../components/commentMarche.vue";
import searchBar from "../components/ui/searchBar.vue";

export default {
  components: {
    CommentMarche,
    searchBar,
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      oldrefrence: null,
      errorD: false,
      errorU: false,
    };
  },
  methods: {
    ...mapActions([
      "visibilite_metier",
      "allcategorieMetier",
      "store_visibilite_categorie_metier",
    ]),

    hideModal(ref) {
      this.$refs[ref].hide();
    },

    active(item) {
      var list = this.getAllmetierV;
      if (item.active) {
        // Vérifier si l'objet existe déjà dans la liste en comparant les identifiants
        let objectExists = list.some((obj) => obj.id == item.id);

        // Si l'objet n'existe pas encore dans la liste, l'ajouter
        if (!objectExists) {
          list.push({ id: item.id });
          this.store_visibilite_categorie_metier({
            categorie_metier_id: list.map((item) => item.id),
          }).then(() => {
            this.visibilite_metier();
          });
        }
      } else {
        list = this.getAllmetierV.filter((obj) => obj.id != item.id);
        this.store_visibilite_categorie_metier({
          categorie_metier_id: list.map((item) => item.id),
        }).then(() => {
          this.visibilite_metier();
        });
      }
    },

    updateSearch(s) {
      this.page = 1;
      this.search = s;
    },

    pagination(paginate) {
      this.page = paginate;
      this.allcategorieMetier({
        page: this.page,
        per_page: this.perPage,
        search: this.search,
      });
    },

    setup(refresh = false) {
      if (refresh) this.page = 1;
      this.allcategorieMetier({
        page: this.page,
        per_page: this.perPage,
        search: this.search,
      });
      this.visibilite_metier();
    },
  },

  mounted() {
    this.setup(true);
  },

  computed: {
    ...mapGetters([
      "getAllcategorieMetier",
      "getLoadingcategorieMetier",
      "getTotalRowcategorieMetier",
      "getTabs",
      "getAllmetierV",
      "getLoadingprestation",
    ]),

    computedAllMetier() {
      var liste = this.getAllcategorieMetier;
      liste?.forEach((element) => {
        const objectFound = this.getAllmetierV.find(
          (item) => item.id == element.id
        );
        if (objectFound) {
          element.active = true;
        } else {
          element.active = false;
        }
      });
      return liste;
    },
  },
};
</script>

<style lang="scss" scoped>
.inner-container .page-header h1 {
  width: 100%;
}
.content {
  & p {
    font-size: 12px;
    font-weight: 500;
    padding: 3px 0;
    margin: 0;
  }
}

.w-100 {
  background-color: #f8f8f8;
  padding: 4px;
  overflow-x: auto;
}

body {
  background: #fafdff;
}

.actionModel {
  position: relative;
  padding: 15px 0 0;

  & .block-spinner {
    display: flex;
    justify-content: center;
  }

  & .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.filtre {
  display: flex;
  align-items: center;
}
</style>
